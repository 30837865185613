import React from "react";

const Footer = ({ className = "" }) => {
  return (
    <footer
      className={`text-center sm:relative sm:shrink-0 sm:pt-2 text-xs sm:text-md w-full h-16 bg-ensuperlig-1 mt-3 ${className}`}
    >
      <div className="sm:absolute sm:bottom-2 text-white sm:left-6 mt-1">
        © 2025 ensuperlig – Tüm hakları saklıdır.
      </div>
      <div className="sm:absolute sm:bottom-2 text-white sm:right-6">
        <div className="font-bold mt-1 mb-1 sm:mb-1">İletişim</div>
        <a href="mailto:ensuperligtr@gmail.com">ensuperligtr@gmail.com</a>
      </div>
    </footer>
  );
};

export default Footer;
